import { useEffect, useState, useCallback, useMemo } from 'react';
import { Select, SelectItem, Button, classNames, TruncateText, usePrevious } from '@panwds/react-ui';
import { CheckIcon } from '@panwds/icons';
import { GlobalStore } from '../api/GlobalStore';
import { SessionStorage } from '../utils/SessionStorage';
import { APIManagerInstance } from '../api';
import Logger from '../utils/Logger';
import { isNil, isNilOrEmptyString } from '../utils/isNil';
import { initializePrecache  } from "../utils/initiatePrecache";

type TenantSelectorProps = {
	id: string,
	onChange?: (selectedItems: TenantOption[]) => void,
	items?: { label: any; value: any; group: string; context: any }[]
	addClassName?: string;
}

type TenantOption = {
	fqdn: string;
	group: string;
	label: string;
	gcsTenantId?: string;
} & SelectItem;

export function TenantSelector(props: TenantSelectorProps): JSX.Element {
	const { id, onChange, items, addClassName } = props
	const { getSaseGlobalState, setSaseGlobalState } = GlobalStore
	const { subtenant, tenantList } = getSaseGlobalState() || {}
	
	// check data in props first, fallback to global state
	const tenantRawData = items || tenantList;

	const [tenantData, setTenantData] = useState<TenantOption[]>([])
	const expandedOptions: TenantOption[] = []

	const [selectedItems, setSelectedItems] = useState<TenantOption[]>([])
    const [apiServer, setFQDN] = useState<string>();
	const selectedTenant = useMemo(() => selectedItems?.[0]?.value, [selectedItems]);
	const selectedSubTenant = useMemo(() => selectedItems?.[0]?.context?.value, [selectedItems]);
	const prevSelectedTenant = usePrevious(selectedTenant)
	
	const updateFQDN = useCallback((fqdn: string) => {
        APIManagerInstance.updateAPIServer(fqdn);
        setFQDN(fqdn);
    }, [])

	const handleTenantChange = (items: any[]) => {
		const { fqdn, gcsTenantId, cyrEnv } = items[0] || {};
		const [tsgId, subtenantId] = gcsTenantId.split(":");
		
		// Communicate to server to start precache for performance latent views for selected TSG
        // feature flag backed in the BE
		// trigger when selector onchange value
		if((prevSelectedTenant && prevSelectedTenant !== tsgId) || (subtenant && subtenant !== subtenantId) ) {
			initializePrecache(tsgId, subtenantId)
		}		
		setSelectedItems(items)
		if(subtenant !== subtenantId) {
			setSaseGlobalState({ tenantId: tsgId, subtenant: subtenantId, fqdn, cyrEnv })
			onChange && onChange(items);
		}
	};

	// Initial raw data manipulation - Will only run once
	useEffect(() => {
		if(!tenantData.length) {
		tenantRawData?.map((option: any, i: number) => {
			const { label, value:parentValue, options: childOptions, fqdn, ...rest } = option
			expandedOptions.push({ context: { ...option }, label, value:parentValue, key: `${parentValue}.heading-${i}`, group: 'heading', fqdn, ...rest })
			
			childOptions?.forEach((option: { label: any; value: any; group: string, key: string }) => {
        		const { label, value, ...rest } = option;
				expandedOptions.push({ context: { ...option }, label, value:parentValue, fqdn, ...rest, key: `${value}.child-${i}`, group: 'children' })
			})
		})

		setTenantData(expandedOptions)
		}
	}, [tenantRawData])

	useEffect(() => {
        if (isNil(selectedTenant)) {
            SessionStorage.getInstance().removeItem("subtenant");
        } else {
            SessionStorage.getInstance().set( "tenant", `${selectedTenant}` );
        }
    }, [selectedTenant]);

    useEffect(() => {
        if (isNil(selectedSubTenant)) {
            SessionStorage.getInstance().removeItem("subtenant");
        } else {
            SessionStorage.getInstance().set( "subtenant", `${selectedSubTenant}` );
        }
    }, [selectedSubTenant]);
	
	// Initial tenant data change - should only run once on load
	useEffect(() => {
		if (tenantData.length > 0) {
			// if global state has subtenant, set it or set the first option
			const selectOption = tenantData.find((data: TenantOption) => data?.context?.value === subtenant);

            // trigger the change method because we need all the same operations here too
            if(selectOption) {
                handleTenantChange([selectOption]);    
            } else {
				// triggered initially on load, initially setting selected tenant to first tenant from response
				const firstTenant = tenantData[0];			
                handleTenantChange([firstTenant]);
				if(firstTenant?.gcsTenantId) {
					const [tsgId, subtenantId] = firstTenant.gcsTenantId.split(":");				
					initializePrecache(tsgId, subtenantId)		
				}		
            }
		}
	}, [tenantData])

	// If selected tenant changes, or tenant raw data changes, or fqdn changes
	useEffect(() => {
        if (isNil(selectedTenant)) {
            SessionStorage.getInstance().removeItem("subtenant");
        } else {
            SessionStorage.getInstance().set( "tenant", `${selectedTenant}` );
            const fqdn = tenantList.find((item: any) => item.value === selectedTenant)?.fqdn;
            updateFQDN(fqdn);
        }
    }, [selectedTenant, tenantRawData, updateFQDN]);

	// If tenant was switched then update the subtenantList
    useEffect(() => {
        if (!isNilOrEmptyString(selectedTenant) && !isNilOrEmptyString(apiServer)) {
            APIManagerInstance.fetchTenantInfo(selectedTenant as string).then(response => {
				setSaseGlobalState({ subtenantList: response?.[0]?.data });
			}).catch(error => {
                Logger.error(`Failed to fetch FQDN for tenant ${selectedTenant}`, error);
            })
        }
    }, [apiServer, selectedTenant]);

	return (
		<div id={`${id}_tenantSelector`} className={classNames(
			"tw-flex tw-flex-col tw-space-y-2 tw-w-96",
			addClassName
			)}>
			<Select
				fitToItemWidth
				dataTestId={`${id}_tenantSelector_search`}
				dataMetrics={`${id}_tenantSelector_search`}
				fullWidth
				items={tenantData}
				onChange={({ selectedItems }) => handleTenantChange(selectedItems)}
				selectedItem={selectedItems}
				key="tenant-selector-0"
				button={
					<Button isMenu size="md" appearance="tertiary" fullWidth buttonType="form-select" addClassName="tw-r">
						<TruncateText>{selectedItems?.[0]?.context?.label || 'Tenants'}</TruncateText>
					</Button>
				}
				enableSearch
                itemToString={item => `${item?.context?.label} ${item?.gcsTenantId}`}
				formatOption={{
					view: (item: any) => (
						<div
							className={classNames(
								'tw-flex tw-items-center',
								item?.group === 'heading' && 'tw-pointer-events-none'
							)}
						>
							<span className={classNames(item?.group === 'children' && 'tw-pl-6')}>
								{item?.context?.gcsTenantId === selectedItems?.[0]?.context?.gcsTenantId &&
									item?.group === 'children' && (
										<span className="tw-pr-2">
											<CheckIcon
												size="sm"
												className="tw-text-green-600 dark:tw-text-dark-bg-green"
											/>
										</span>
									)}
								{item.context?.label}
							</span>
						</div>
					)
				}}
			/>
		</div>
	)
}
