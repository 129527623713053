import { Typeahead, TypeaheadProps } from "@panwds/react-ui"

type SearchProps = {
    expanded?: boolean,
} & TypeaheadProps;


export const Search = (props: SearchProps) => {

    const { expanded, options = [], ...rest } = props;

    return (
        <div className="tw-w-96">
        <Typeahead
            options={options}
            noOptionsText="No results found"
            showClearIcon
            {...rest}
        />
      </div>
    )
}