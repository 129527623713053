import { isObj } from './isObject'

export default function isEmpty(variable: unknown): boolean {
  if (variable === undefined || variable === null) {
    return true
  }
  if (isObj(variable)) {
    return Object.keys(variable).length === 0
  }
  if (Array.isArray(variable)) {
    return variable.length === 0
  }
  if (typeof variable === 'string') {
    return variable === ''
  }
  return false
}
