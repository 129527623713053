import isEmpty from './isEmpty'

export class SessionStorage {
  private static instance: SessionStorage
  private constructor() {
    /** */
  }

  public static getInstance(): SessionStorage {
    if (isEmpty(this.instance)) {
      this.instance = new this()
    }
    return this.instance
  }

  public set(key: string, value: string | Record<string, unknown> | Record<string, unknown>[]): void {
    const itemValue = typeof value === 'string' ? value : JSON.stringify(value)
    sessionStorage.setItem(key, itemValue)
  }

  public getString(key: string): string|null {
    return sessionStorage.getItem(key)
  }

  public getObject(key: string): Record<string, unknown>|undefined {
    const itemValue = sessionStorage.getItem(key)
    if (isEmpty(itemValue)) {
      return undefined
    }
    try {
      return JSON.parse(itemValue || "")
    } catch (e) {
      return undefined
    }
  }
  public removeItem(key: string): void {
    sessionStorage.removeItem(key)
  }

  public removeAll(): void {
    sessionStorage.clear()
  }
}
