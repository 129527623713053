import { cloneDeep } from "lodash";

/*
    Method to extract features for the given user's role from the auth object
    `subtenant` is an optional param to support feature specific handling
*/


export function extractFeatures(auth, subtenant = "") {
    const userRoles = [];
    const userFeatures = new Map();

    /* Get all the roles for the logged in user */
    auth?.access?.forEach((a) => {
        const isPaiApp = ["prisma_access", "prisma_access_panorama", "all", ""].some((appName) => appName === a.app_id)
        if (isPaiApp) {
            userRoles.push(...a.roleNames)
        }
    });

    /*
        If the user has superuser access, then return the superuser features without any merge
    */
    if (userRoles.includes("superuser")) {
        const key = subtenant ? `netsec.features.${subtenant}.superuser` : "netsec.features.superuser";

        return auth?.properties?.get(key)?.data ?? [];
    }

    /* Get all the features associated with the user roles with merged permissions */
    for (const role of userRoles) {
        const key = subtenant ? `netsec.features.${subtenant}.${role}` : `netsec.features.${role}`;
        const features = auth?.properties?.get(key)?.data ?? [];

        /*
            If any role is a custom role(missing MFE feature data)
                return empty feature set to fallback to the feature API
        */
        if (features.length === 0) {
            return [];
        }

        try {
            features.forEach((feature) => {
                const currentFeatureName = feature.feature;
                const currentFeature = cloneDeep(feature);

                /* If feature is present from another role, merge the permissions */
                if (userFeatures.has(currentFeatureName)) {
                    const existingPermissions = userFeatures.get(currentFeatureName).permission;

                    currentFeature.permission = [...new Set([...existingPermissions, ...feature.permission])];
                }

                userFeatures.set(currentFeatureName, currentFeature);
            });
        } catch(e) {
            console.error("`unified-app` failed to extract features from MFE, falling back to the feature API call", e);

            return [];
        }
    }

    return Array.from(userFeatures.values());
}
