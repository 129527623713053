import { TokenManager } from "@panw/token-manager";
import { MicroApp, OneOf } from "../types/types";

export type MenuItemProps = {
  id: string
  icon?: string
  title: string
  children?: MenuItemProps[]
}

export enum AppBarMenuLink {
  DASHBOARDS = 'dashboards',
  SUMMARY = 'sase-summary',
  REMOTE_NETWORK = 'sase-remote-networks',
  MOBILE_USER = 'sase-mobile-users',
  MOBILE_USER_EP = 'sase-mobile-users-ep',
  OKYO_GARDE = 'sase-okyo',
  ALERTS = 'sase-alerts',
  ALERTS_LIST = 'alerts_list',
  ALERTS_SUBSCRIPTION = 'alerts_subscription',
  NETWORK_OBJECTS = 'network_objects',
  PRISMA_ACCESS_LOCATIONS = 'sase-locations',
  TUNNELS = 'sase-tunnels',
  SERVICE_CONNECTIONS = 'sase-service-connections',
  PA_UPGRADES = 'sase-upgrade',
  AIOPs_DASHBOARD = 'sase-aiops-dashboard',
  AIOPs_APP_ACCESS_ANALYZER = 'sase-aiops-aaa',
  AIOPs_PCM = 'sase-aiops-pcm',
  INCIDENTS = 'sase-incidents',
  INCIDENT_POLICY = 'sase-incident-policy',
  ALERTS_POLICY = 'sase-alert-policy',
  INCIDENT_DETAILS = 'sase-incidents-details',
  APP_ACCESS_ANALYZER_QUERY_RESULTS = 'sase-aaa-results',
  APP_ACCESS_ANALYZER_QUERY = 'sase-aaa-query',
  NOTIFICATION_PROFILES = 'sase-notifications',
  NEW_NOTIFICATION_PROFILE = 'sase-notification-profile',
  INCIDENT_SETTINGS = 'sase-incident-settings',
  UPDATE_NOTIFICATION_PROFILE = 'sase-notification-profile-edit',
  ZTNA_CONNECTORS = 'sase-ztna',
  NETWORK_SERVICES = 'sase-network-services',
  POLICY_MGMT = 'sase-change-mgmt',
}

export enum AppBarMenuItem {
  DASHBOARDS = 'generic.dashboards',
  SUMMARY = 'generic.summary',
  REMOTE_NETWORK = 'generic.rn',
  MOBILE_USER = 'generic.mu',
  OKYO_GARDE = 'generic.okyoGarde',
  ALERTS = 'generic.alerts',
  ALERTS_LIST = 'generic.alertLists',
  NETWORK_OBJECTS = 'generic.networkObjects',
  PRISMA_ACCESS_LOCATIONS = 'generic.pal',
  TUNNELS = 'generic.tunnels',
  SERVICE_CONNECTIONS = 'generic.sc',
}

export enum SummaryTabs {
  SUMMARY = 'generic.summary',
  MAP_VIEW = 'generic.mv',
  TYPOLOGY_VIEW = 'generic.typologyView',
}

export enum RemoteNetworksTabs {
  MONITORING_SUMMARY = 'generic.ms',
  MAP_VIEW = 'generic.mv',
  TYPOLOGY_VIEW = 'generic.topologyView',
  SITE_LITE = 'generic.siteList',
}

export const SUMMARY_SUBTITLE = 'generic.description'

export enum DefinitionType {
  Dashboard = 'dashboard',
  View = 'view',
  Widget = 'widget',
}

export enum AnalyticsType {
  Dashboard = 'dashboard',
  View = 'view',
  Widget = 'widget',
  Query = 'query',
  Data = 'data',
  Form = 'form',
  Filter = 'filter',
  Custom = 'custom',
  Header = 'header',
  Timezone = 'timezone',
  None = '',
}

export enum ResponseHeaders {
  ContentType = 'Content-Type',
}

export enum LicenseChart {
  NoLicense = 'No License',
  Expired = 'License has Expired',
  Days = 'Days Remaining Until',
  None = 'none',
}

export enum ContentType {
  ApplicationJson = 'application/json',
}

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGVuZ3V5ZW4iLCJhIjoiY2thZnY3bXEwMDBsNzJ5bzZyM2x6eGUxMyJ9.0cQLiEggi-MUBs9jk8fAsA'

export const UPDOWNWARNING = {
  down: 0,
  up: 1,
  warning: 2,
  unknown: 3,
}

export enum TunnelStateEnum {
  Init,
  Up,
  Inactive,
  Down,
  Unknown,
}

export enum OkyoTunnelStateEnum {
  up,
  down,
  init,
  inactive,
}
export enum TunnelState_Chart_LegendEnum {
  Up,
  Down,
  Inactive,
  Init,
  Unknown,
}

export enum TunnelStateMonitoringEnum {
  Down,
  Up,
  NotConfigured,
}

export enum InstanceStateEnum {
  Down,
  Up,
}

export enum InstanceState_Chart_LegendEnum {
  Up,
  Down,
}

export enum UpDownWarningEnum {
  Down,
  Up,
  Warning,
  Unknown,
}

export enum UpDownWarning_Chart_LegendEnum {
  Up,
  Down,
  Warning,
  Unknown,
}

export enum NodeTypeEnum {
  Hub = 47,
  RemoteNetwork,
  MobileUserGateway,
  MobileUserPortal,
  ServiceConnection,
}

export enum AlertStatus {
  Resolved,
  Open,
}

export enum TunnelProps {
  up = 'site_up_tunnels',
  all = 'site_all_tunnels',
}

export enum SiteAndTunnel_Chart_LegendEnum {
  Not_Available,
  Inactive,
  Warning,
  Down,
  Up,
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export const MINCHARTVALUE = 0.20001

export const ERROR_COLOR = '#D94949'

export const RANGE_LIST = [60, 900, 10800, 86400, 604800, 2592000]

export const CustomIntervalRange = {
  ONE_MINUTE: 60,
  FIFTEEN_MINUTE: 900,
  ONE_HOUR: 60 * 60,
  THREE_HOUR: 10800,
  TWENTY_FOUR_HOUR: 86400,
  SEVEN_DAY: 604800,
  THIRTY_DAY: 2592000,
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
}

export const CustomIntervalRangeMappings = {
  h: CustomIntervalRange.HOUR,
  m: CustomIntervalRange.MINUTE,
  d: CustomIntervalRange.DAY,
}

export const CustomInterval = {
  CUSTOM: 'custom',
  TITLE: 'Custom Filter',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  DATE: 'date',
}

export enum TimeUnits {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Month = 'Month',
  Year = 'Year',
}

interface AccountDetails {
  accountId: number
  accountName: string
  tenant: string
  lsTenantId: string
}

interface FQDNServersItf {
  apiServer: string
  gc_fqdn: string
}
// adding a new service please update these types
export type FQDNTypes = 'apiServer' | 'gc_fqdn'
export const GLOBAL_SERVICE_FQDN_NAME = 'gc_fqdn'
export const API_SERVER_FQDN_NAME = 'apiServer'


export type FQDNServers = OneOf<FQDNServersItf, FQDNTypes>
export type MainStateFnType = () => Record<string, unknown>

export interface InitialState {
  tokenManager: TokenManager;
  appType: string
  serverFqdns: FQDNServers
  demServerFqdns: FQDNServers
  accountInfo: AccountDetails
  appPortalUrl: string
  locale?: string
  accountId?: number
  getToken?: () => string
  hasTsgSupport?: () => boolean
  tsgId?: string
  isPrivateSite?: boolean
  getMainState: MainStateFnType
}

export enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum Position {
  Top = 'top',
  Bottom = 'bottom',
}

export enum Severity {
  'Low' = 2,
  'Medium' = 3,
  'High' = 4,
}

export enum ConnectivityStateEnum {
  Init,
  Up,
  Inactive,
  Down,
}

export enum DeviceStateEnum {
  Connected = 'Connected',
  ConnectionProgress = 'Connection Progress',
  ConnectionFailed = 'Connection Failed',
  RegistrationProgress = 'Registration Progress',
  RegistrationFailed = 'Registration Failed',
  OnboardingFailed = 'Onboarding Failed',
  DeregistrationProgress = 'Deregistration Progress',
  DeregistrationFailed = 'Deregistration Failed',
  OtherProgress = 'Other Progress',
}
export enum TunnelTypes {
  RN = 'Remote Network',
  Okyo = 'Okyo',
  SC = 'Service Connection',
}
// Incident Details - Activity log types
export enum HistoryActivityTypes {
  INC_AL_ASSOCIATE,
  INC_CREATED,
  INC_HAS_CHILD,
  INC_HAS_PARENT,
  INC_NOTIFICATION_SENT,
}
// Incident Details - Activity log types
export enum TransitionsActivityTypes {
  INC_ACK_UPDATE,
  INC_CLEARED,
}
// Incident Details - Activity log types
export enum NotificationsActivityTypes {
  INC_NOTIFICATION_SENT,
}

export const ShadowVariants = {
  0: 'none',
  1: 'sm',
  2: 'md',
  3: 'lg',
  4: 'xl',
  5: 'xxl',
}

export enum ErrorDataCode {
  DATA10001,
  DATA10002,
  DATA10003,
  DATA10004,
  REST10001,
  REST10002,
  REST10003,
  DEFINITION,
  LICENSE,
  TENANT_FAILURE,
  DOWNLOAD_FAILURE,
  AUTH_DATA,
  TM_ERROR,
  NO_DATA,
  APIKEY10000,
  APIKEY10001,
  APIKEY10002,
  APIKEY10003,
  UNKNOWN,
  GRAPHQLERROR
}

export enum App {
	PRISMA_ACCESS = "prisma_access",
}

export const MANDATORY_APPS = [App.PRISMA_ACCESS];

export const MICRO_APP_ADEM: MicroApp = 'adem';
export const MICRO_APP_PAI: MicroApp = 'pai_oneapp';
export const MICRO_APP_SDWAN: MicroApp = 'sdwan';
