import { getState, actions } from 'sparky-framework';
import { MicroApp } from '../types/types';

export const setSaseGlobalState = (newState: {[key:string] : any}) => {
	const { saseIaGlobalState } = getState();
	if (saseIaGlobalState) {
        actions.setValues({ saseIaGlobalState: { ...saseIaGlobalState, ...newState } });
    } else {
        actions.setValues({ saseIaGlobalState: newState });
    }
}
export const getSaseGlobalState = () => {
	const { saseIaGlobalState } = getState();
	return saseIaGlobalState;
}

export const setMicroAppLicenseInfo = (microApp: MicroApp, microAppLicenseInfo: unknown) =>{
    const { saseIaGlobalState } = getState();
    let licenseInfo = saseIaGlobalState['License'];

    licenseInfo = {...licenseInfo, [microApp]: microAppLicenseInfo};

    actions.setValues({ saseIaGlobalState: { ...saseIaGlobalState,  license: licenseInfo} });
}

export const setMicroAppTenantInfo = (microApp: MicroApp, microAppTenantInfo: unknown) =>{
    const { saseIaGlobalState } = getState();
    let tenantInfo = saseIaGlobalState['Tenant'];

    tenantInfo = {...tenantInfo, [microApp]: microAppTenantInfo};

    actions.setValues({ saseIaGlobalState: { ...saseIaGlobalState,  tenant: tenantInfo} });
}

export const getMicroAppLicenseInfo = (microApp: MicroApp) => {
    const saseIaGlobalState = getSaseGlobalState();
    const licenseInfo = saseIaGlobalState['License'][microApp];
    return licenseInfo
}

export const getMicroAppTenantInfo = (microApp: MicroApp) => {
    const saseIaGlobalState = getSaseGlobalState();
    const tenantInfo = saseIaGlobalState['Tenant'][microApp];
    return tenantInfo
}

export const GlobalStore = { setSaseGlobalState, getSaseGlobalState, getMicroAppTenantInfo, getMicroAppLicenseInfo, setMicroAppTenantInfo, setMicroAppLicenseInfo};
