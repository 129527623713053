import { Tenant, TenantRow } from "../types/redux-types";
import { getFrameworkVars } from '@sparky/framework';

export default function (props: {
    tenantRow: TenantRow,
    hasTsgSupport: boolean,
}): Tenant {
    const { tenantRow, hasTsgSupport } = props;
    const { private_site_origin } = getFrameworkVars();
    const isPrivateApp = Boolean(private_site_origin);
    return {
        id: hasTsgSupport ? tenantRow?.tenant_service_group : tenantRow?.tenant_id,
        name: tenantRow?.tenant_name,
        displayName: isPrivateApp ? tenantRow?.tenant_display_name : tenantRow?.tenant_name,
        tsgId: tenantRow?.tenant_service_group,
        gcsTenantId: tenantRow?.gcs_tenant_id,
        cdlTenantId: tenantRow?.tenant_id,
        platformType: tenantRow?.platform_type,
    }
}
