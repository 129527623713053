export interface Deferred<T> extends Promise<T> {
  resolve(arg: unknown): void
  reject(reason: unknown): void
}

export const createDeferred = <T>(): Deferred<T> => {
  let resolve: any
  let reject: any

  const promise = new Promise<T>((resolver, rejector) => {
    resolve = resolver
    reject = rejector
  }) as unknown as Deferred<T>

  promise.resolve = (arg) => {
    resolve(arg)
  }

  promise.reject = (arg) => {
    reject(arg)
  }

  return promise
}
