import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { isNil, isNilOrEmptyString } from "../utils/isNil";
import { SessionStorage } from "../utils/SessionStorage";
import { APIManagerInstance } from "../api/ApiManager";
import Logger from "../utils/Logger";
import isEmpty from "../utils/isEmpty";
import { ErrorDataCode } from "../constants/uiConstants";
import { Select, Button } from "@panwds/react-ui";
import { CheckIcon } from "@panwds/icons";
import { GlobalStore } from '../api/GlobalStore';

import { SubTenantSelectorPropTypes } from "../types/types";

type TenantSubtenantState = {
    selectedValue:Record<string, unknown>;
    tenants: Array<Record<string, unknown>>;
    selectedTenant: string;
    selectedSubTenant: string;
}

type StateAction =
    | { type: "loadTenant",
    tenants: Array<Record<string, unknown>>, tenant: string, subtenant: string, selectedValue: Record<string, unknown> }
    | { type: "changeSubTenant", tenant: string, subtenant: string, selectedValue: Record<string, unknown> }

function stateReducer(state: TenantSubtenantState, action: StateAction): TenantSubtenantState {
    switch (action.type) {
    case "loadTenant":
        return {
            ...state,
            tenants: action.tenants,
            selectedTenant: action.tenant,
            selectedSubTenant: action.subtenant,
            selectedValue: action.selectedValue
        }
    case "changeSubTenant":
        return {
            ...state,
            selectedTenant: action.tenant,
            selectedSubTenant: action.subtenant,
            selectedValue: action.selectedValue
        }
    }
}

export function SubTenantSelector (props: SubTenantSelectorPropTypes): JSX.Element {
    const { getSaseGlobalState, setSaseGlobalState } = GlobalStore;
    const { tenantId, subtenant, subtenantList = [] } = getSaseGlobalState() || {};
    const tenantData = useMemo(() => subtenantList.map((data: any) => ({ value: data.sub_tenant_display_name, context: data })), [subtenantList]);

    const { id, onChangeTenant } = props;
    const currentTenantValue: string = tenantId;
    const currentSubTenantValue: string = subtenant || subtenantList[0]?.value || "";
    
    const [{
        selectedValue,
        tenants,
        selectedTenant,
        selectedSubTenant
    }, stateDispatch] = useReducer(stateReducer, {
        selectedValue: tenantData?.find(item => item.context.sub_tenant_id === subtenant) || tenantData[0] || [],
        tenants: subtenantList || [],
        selectedTenant: currentTenantValue,
        selectedSubTenant: currentSubTenantValue
    })
    const [hasError, setError] = useState();

    const handleSubTenantChange = useCallback((data: any) => {
        stateDispatch({
            type: "changeSubTenant",
            tenant: data.context.tenant_id,
            subtenant: data.context.sub_tenant_id,
            selectedValue: [data]
        });
        
         setSaseGlobalState({ subtenant: data.context.sub_tenant_id });

        if(onChangeTenant) {
            onChangeTenant({ tenant: data.context.parent_id, subtenant: data.context.sub_tenant_id });
        }
    }, []);

    useEffect(() => {
        if (tenantData.length > 0) {
            const tenant = selectedTenant ?? tenantData[0]["value"];
            const subtenant = selectedSubTenant || tenantData[0].context.sub_tenant_id;
            const currentSubTenantValue = tenantData.filter(item => item.context.sub_tenant_id === subtenant);
            const selectedValue =  !isEmpty(currentSubTenantValue) ? currentSubTenantValue : tenantData[0];

            if(!isEmpty(selectedValue)) {

            stateDispatch({
                    type: "loadTenant",
                    tenants: tenantData,
                    tenant,
                    subtenant,
                    selectedValue
                })
                if(onChangeTenant) {
                    onChangeTenant({ tenant, subtenant });
                }
            }
            // set global state only when it is really needed
            if(subtenant !== selectedSubTenant) {
                setSaseGlobalState({ subtenant });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantData]);

    useEffect(() => {
        if (!isNil(selectedTenant)) {
            SessionStorage.getInstance().set( "tenant", `${selectedTenant}` );
        }
        SessionStorage.getInstance().removeItem("subtenant");
    }, [selectedTenant]);

    useEffect(() => {
        if (isNil(selectedSubTenant)) {
            SessionStorage.getInstance().removeItem("subtenant");
        } else {
            SessionStorage.getInstance().set( "subtenant", `${selectedSubTenant}` );
        }
    }, [selectedSubTenant]);

    if (!isNilOrEmptyString(hasError)) {
        return <span>{ErrorDataCode[ErrorDataCode.TENANT_FAILURE]}</span>
    }

    return (<>
        <div id={`${id}_tenantSelector`} className="tw-flex tw-flex-col tw-space-y-2 tw-w-60">
            <Select
                fitToItemWidth
                dataTestId={`${id}_tenantSelector_search`}
                dataMetrics={`${id}_tenantSelector_search`}
                fullWidth
                items={tenantData}
                onChange={({ selectedItem }) => handleSubTenantChange(selectedItem)}
                selectedItem={selectedValue}
                key="tenant-selector-0"
                enableSearch
                itemToString={item => `${item?.context?.sub_tenant_display_name}`}
                formatOption={{
                    view: (item) => (
                        <>
                            {item.context?.sub_tenant_id === selectedSubTenant &&
                            <span className="tw-pr-2"><CheckIcon
                                size="sm"
                                className="tw-text-green-600 dark:tw-text-dark-bg-green" /></span>}
                            {item.context?.sub_tenant_display_name}
                        </>
                    ),
                  }}
            >
                {selectedValue[0]?.context?.sub_tenant_display_name || 'Sub Tenants'}
            </Select>
        </div>
    </>);
}
