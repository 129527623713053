import { isNil } from '../utils/isNil';
import { AuthData } from '@panw/token-manager';
import { FQDNServers, } from '../constants/uiConstants';
import { ErrorItf } from '../types/types';
import doFetch from './http-client/do-fetch';

class AdemManager {
  private static instance: AdemManager
  private authData?: AuthData
  private serverFqdns!: FQDNServers
  private supportTsgId!: boolean
  private tsgId!: string
  private getToken!: () => string;

  private constructor() {
    // private constructor to enforce singleton-ness
  }

  public static getInstance(): AdemManager {
    if (isNil(this.instance)) {
      this.instance = new AdemManager()
    }
    return this.instance
  }

  public setServerFQDNs(servers: FQDNServers): void {
    this.serverFqdns = servers
  }


  public setAPIInfo(apiInfo: {
    supportForTsgId: boolean
    tsgId: string
    getToken: () => string
    serverFqdns: FQDNServers
  }): void {
    this.supportTsgId = apiInfo.supportForTsgId;
    this.tsgId = apiInfo.tsgId;
    this.getToken = apiInfo.getToken;
    this.serverFqdns = apiInfo.serverFqdns;
  }

  public getServerFQDNs(): FQDNServers {
    return this.serverFqdns;
  }

  public setAuthData(authData: AuthData): void {
    this.authData = authData;
  }

  public getAuthData(): AuthData {
    return this.authData!;
  }
  
  public async fetchTenantInfo(): Promise<any> {
    const token = this.getToken();
    let url = `${this.serverFqdns}/api/v3/t/${this.tsgId}`
    url = `${(url.startsWith("http://") || url.startsWith("https://")) ? "" : "https://"}${url}`;
   
    const fetchProps = {
      method: 'GET',
      headers: this.buildHTTPHeaders({
        token,
        tsgId: this.tsgId,
      }),
    }
    
    try {
      const res = await doFetch(url, fetchProps)
      return res
    } catch (err: any) {
      const { response } = err

      if (!isNil(response)) {
        const error: ErrorItf = {
          errorCode: `${response.status}`,
          details: response.statusText,
          message: response.statusText,
        }

        const { errorCode } = response?.data ?? {}

        if (!isNil(errorCode)) {
          return Promise.reject(response?.data)
        }

        return Promise.reject(error)
      }
      return Promise.reject(err)
    }
  }

  /**
   * Construct the HTTP headers for each dataservice REST requests.
   * @param props
   * @private
   */
  private buildHTTPHeaders(props: {
    token: string
    tsgId: string
  }): Record<string, string> {
    const { token, tsgId} = props
    const authHeader = `Bearer ${token}`
    
    const headers: any = {
      'content-type': 'application/json',
      Authorization: authHeader,
    }
    
    if (this.supportTsgId === true) {
      headers['Prisma-Tenant'] = tsgId
    }

    return headers
  }
}

export default AdemManager
