import React, { ReactNode } from 'react'
import { PageHeader, classNames } from '@panwds/react-ui'
import { SubTenantSelector } from './SubTenantSelector'
import { GlobalSearch } from './Search'
import { TenantSelector } from './TenantSelector'
import { APIManagerInstance } from '../api'
import { isNil } from '../utils/isNil'
import { LastUpdated } from '../components/Header/LastUpdated'
import { lazyImportComponent, getMicroAppVars } from '@sparky/framework'

export interface HeaderContainerType {
	title: string
	subtitle?: string
	showSearch?: boolean
	topActions?: ReactNode[]
}

/*
 *  Header component will be replaced by this component:
 * - packages/ui/src/components/Header/
 * After thoroughly testing the changes in the QA environment, ensure to delete those files in subsequent commits.
 */

const HeaderContainer = ({
	title,
	showSearch = true,
	topActions = []
}: HeaderContainerType) => {
	const CopilotIconButton = lazyImportComponent('netsec-co-pilot', 'CopilotIconButton')
	const featureFlags = getMicroAppVars('pai')?.featureFlags
	const copilotEnabled = !isNil(featureFlags) ? JSON.parse(featureFlags).copilot_enabled : false
	const gcsTsgEnabled = !isNil(featureFlags) ? JSON.parse(featureFlags).gcs_tsg_enabled : false;

	const bottomActions: ReactNode[] = [<LastUpdated />]

	const Search = <GlobalSearch key="search-0" id="search-0" />
	if (showSearch) {
		bottomActions.push(Search)
	}
	
	if(gcsTsgEnabled) {
		const SubTenantSelectorComponent = (
			<SubTenantSelector key="tenant-selector" id="tenant-selector" />
		)
		bottomActions.push(SubTenantSelectorComponent)
		
	} else {
		const TenantSelectorComponent = APIManagerInstance.isPrivateApp() ? (
			<TenantSelector key="tenant-selector" id="tenant-selector" />
		) : (
			<SubTenantSelector key="tenant-selector" id="tenant-selector" />
		)
		bottomActions.push(TenantSelectorComponent)
	}
	
	const Copilot = (
		<span className="pai-ml-1">
			<CopilotIconButton divider={true} />
		</span>
	)
	if (copilotEnabled) {
		bottomActions.push(Copilot)
	}

	const getActionsComponent = () => {
		return (
			<div className="tw-flex tw-flex-col tw-items-end">
				{topActions && topActions.length > 0 && (
				<div id={`header-0_topActionContainer`} className={classNames("tw-flex tw-items-center tw-py-1", 
						topActions.length == 1 && copilotEnabled === false && "tw-mr-4")}>            
					{topActions.map((action, index) => {
						return (
							<React.Fragment key={`top_${index}`}>
							{action}
							{index !== topActions.length - 1 && (
								<span className="tw-mx-4 tw-text-secondary dark:tw-text-dark-bg-secondary">{'|'}</span>
							)}
							</React.Fragment>
						)
					})}
				</div>
				)}
				{bottomActions && bottomActions.length > 0 && (
					<div id={`header-0_bottomActionContainer`} className="tw-flex tw-items-center tw-gap-2">
						{bottomActions}
					</div>
				)}
			</div>
		)
	}

	return <PageHeader actions={getActionsComponent()} title={title} />
}

export default HeaderContainer
