import { Search } from './Search'
import { useStoreSelector } from "@sparky/framework";
import { useSearch } from './useSearch'

// configuration for search details' routes and category names
const categoryMap = {
	"sites": {
		label: "Sites",
		route: {
			RN: "/monitor/branch-sites/details/bs_details", 
			SC: "/monitor/data-centers/details/dc_details"
		}
	},
	"pa_locations": {
		label: "PA Locations",
		route: "/monitor/palocations/details",
	},
	"applications": {
		label: "Applications",
		route: "/insights/activity_insights_app/apps/details/activity"
	},
    "users": {
		label: "Users",
		route: "/insights/activity_insights_user/users/details"
	}
}

export const GlobalSearch = (props: any) => {
	const tenantId = useStoreSelector(state => state?.saseIaGlobalState?.tenantId);
	const subtenant = useStoreSelector(state => state?.saseIaGlobalState?.subtenant);
	const { options, loading, debouncedHandleInputChange, navigateToDetailsPage } = useSearch({ tenantId, subtenant, categoryMap })

	return (
		<Search
			options={options}
			loading={loading}
			showCategories
			{...props}
			onKeyUp={event => {
				debouncedHandleInputChange(event.target?.value)
			}}
			onChange={navigateToDetailsPage}

		/>
	)
}
