import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { createDeferred } from '../../utils/deferred'
import Logger from '../../utils/Logger'
import { isNil } from '../../utils/isNil'

type HeadersArgs = Headers | Record<string, string> | Iterable<readonly [string, string]> | Iterable<Iterable<string>>

type Body = Blob | Buffer | URLSearchParams | string

export interface CancellablePromise<T> extends Promise<T> {
  cancel?(reason: string): void
}

interface DoFetchOptions {
  body?: Body
  headers?: HeadersArgs
  method?: string
  acceptHeader?: string
  rawAxiosResponse?: boolean
}

class RequestsManager {
  private static instance: RequestsManager

  private requestsCache: Record<string, CancelTokenSource>

  private constructor() {
    this.requestsCache = {}
  }

  public static getInstance(): RequestsManager {
    if (isNil(this.instance)) {
      this.instance = new this()
    }
    return this.instance
  }

  public addRequest(requestId: string, cancelTokenSource: CancelTokenSource): void {
    this.requestsCache[requestId] = cancelTokenSource
  }

  public removeRequest(requestId: string): void {
    delete this.requestsCache[requestId]
  }

  public cancelRequest(requestId: string, message?: string): void {
    this.requestsCache[requestId]?.cancel(message)
    this.removeRequest(requestId)
  }
}

export const cancelRequest = (requestId: string, message?: string): void => {
  RequestsManager.getInstance().cancelRequest(requestId, message)
}

const doFetch = <T>(url: string, options: DoFetchOptions = {}, requestId?: string): CancellablePromise<T> => {
  const { headers: originalHeaders, acceptHeader = 'application/json', rawAxiosResponse } = options

  const dfd = createDeferred()

  const headers = Object.keys(originalHeaders ?? {}).reduce((acc: Record<string, unknown>, key: string) => {
    //@ts-expect-error todo types
    acc[key] = originalHeaders[key]
    return acc
  }, {})

  if (!('content-type' in headers)) {
    headers['content-type'] = 'application/json'
  }

  if (!('accept' in headers) && !isNil(acceptHeader)) {
    headers.accept = acceptHeader
  }

  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const params = {
    headers,
    url,
    method: options.method ?? 'POST',
    data: options.body,
    cancelToken: source.token,
  }

  const p = axios(params as AxiosRequestConfig)

  const reqId = requestId || url
  const reqManager = RequestsManager.getInstance()
  reqManager.addRequest(reqId, source)

  p.then((res) => {
    reqManager.removeRequest(reqId)
    const resolveValue = rawAxiosResponse ? res : res?.data
    dfd.resolve(resolveValue)
  }).catch((err) => {
    reqManager.removeRequest(reqId)
    if (axios.isCancel(err)) {
      // Cancelled requests should not be considered errors.
      Logger.info(`Request canceled: ${err.message}`)
    } else {
      dfd.reject(err)
    }
  })

  ;(dfd as unknown as CancellablePromise<T>).cancel = (reason: string) => {
    source.cancel(reason ?? 'Cancelled by user')
  }

  return dfd as unknown as CancellablePromise<T>
}

export const post = <T>(url: string, options?: Partial<DoFetchOptions>): CancellablePromise<T> => {
  const opts = {
    ...options,
    method: 'POST',
  }

  return doFetch<T>(url, opts)
}

export const get = <T>(url: string, options?: Partial<DoFetchOptions>): CancellablePromise<T> => {
  const opts = {
    ...options,
    method: 'GET',
  }

  return doFetch<T>(url, opts)
}

export const del = <T>(url: string, options?: Partial<DoFetchOptions>): CancellablePromise<T> => {
  const opts = {
    ...options,
    method: 'DELETE',
  }

  return doFetch<T>(url, opts)
}

export default doFetch
