export function getMFEPropertyAdemLicense(auth) { 
    return (
        auth?.properties?.get('adem.license')?? 
        auth?.properties?.get('ademLicense')      
    );
  }


export function getMFEPropertySubtenant(auth) {    
    return (
      auth?.properties?.get('netsec.subtenant')
    );
  }