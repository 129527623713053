import { useEffect, useState } from "react";
import { isNil } from "../utils/isNil";
import { GLOBAL_SERVICE_FQDN_NAME } from "../constants/uiConstants";
import { TenantRow } from "../types/redux-types";
import { APIManagerInstance } from "../api/ApiManager";
import useMountedComponent from "../utils/useMountedComponent";
import Logger from "../utils/Logger";
import transformTenant from "../utils/transformTenant";
import { OneOf } from "../types/types";
import { GlobalStore } from './GlobalStore'

export interface TenantFetcherInterface {
  tenantId: string;
  shouldLoadTenants: boolean;
}

type TenantRequestHandler = OneOf<
  TenantFetcherInterface,
  "tenantId" | "shouldLoadTenants"
>;

/**
 * For now this is to retrieve all the tenants available. Once we integrate with Fawkes tenant ID will come from FAWKES
 * and will use this to get tenant name and subtenants for this tenant.
 * This should be only called once
 */
export default function useTenantRequestHandler(
  props: TenantRequestHandler
): Record<string, any> {
  const { tenantId, shouldLoadTenants } = props;
  const { getSaseGlobalState, setSaseGlobalState } = GlobalStore;
  const saseIAGlobalState = getSaseGlobalState();
  const tenantData = saseIAGlobalState?.tenant?.data;
  const currentTenant = saseIAGlobalState?.tenantId;
  const currentSubTenant = saseIAGlobalState?.subtenant;
  const subtenantList = saseIAGlobalState?.subtenantList;

  const [state, setState] = useState({
    isLoading: false,
    hasError: false,
    tenant: isNil(tenantData) ? null : { data: tenantData },
    tenantId: currentTenant,
    subtenantId: currentSubTenant,
    subtenantList: subtenantList || [],
  });
  const { isLoading, hasError, tenant } = state;

  const isComponentMounted = useMountedComponent();

  // Logic to load for GCS or Standalone App
  const tenantToFetch = shouldLoadTenants
    ? "?sub_tenant=false"
    : `/${tenantId ?? ""}`;
  const fqdnName = shouldLoadTenants ? GLOBAL_SERVICE_FQDN_NAME : undefined;
  const API_RESOURCE = APIManagerInstance.getAPIResource();

  // Load the tenant data
  useEffect(() => {
    if (
      !isLoading &&
      isNil(tenant) &&
      isNil(shouldLoadTenants) &&
      !isNil(tenantId)
    ) {
      setState({
        ...state,
        isLoading: true,
        hasError: false,
      });
      APIManagerInstance
        .fetchQuery({
          queryPath: `${API_RESOURCE}/account/tenant${tenantToFetch}`,
          method: "GET",
          tenant: tenantId || "",
          fqdnName,
          requestId: "tenant",
        })
        .then((response) => {
          if (!isComponentMounted()) {
            return;
          }

          const tenant = shouldLoadTenants
            ? response?.data
            : response?.data?.filter((item:TenantRow) => {
                const tenant = transformTenant({
                  tenantRow: item,
                  hasTsgSupport: APIManagerInstance.hasTsgSupport(),
                });
                return tenant.id === tenantId;
              });
          let tenantData;
          if (Array.isArray(tenant) && tenant.length > 0) {
            tenantData = { data: tenant };
            setSaseGlobalState({ data: { data: tenant } });
          } else {
            tenantData = response;
            setSaseGlobalState({ data: response });
          }
          setState({
            ...state,
            isLoading: false,
            hasError: false,
            tenant: tenantData,
          });
        })
        .catch((error) => {
          if (!isComponentMounted()) {
            return;
          }
          Logger
            .error(`Failed to fetch tenant ${tenantId}`, error);
          setState({
            ...state,
            hasError: true,
            isLoading: false,
          });
        });
    }
  }, [tenantId]);

  return { isLoading, hasError, tenant };
}

