import {
    getUserLanguage,
    hasTsgSupport,
    getCurrentTsgId,
    getFrameworkVars,
    getMicroAppVars,
    getAuthState,
    getState,
    getFrameworkServiceUrl,
} from '@sparky/framework';
import { isNil } from "@saseia/ui";

export const extractAuthDataComposed = (props) => {
    const { auth, authInfo } = props;
    /** AIOPS-8509: Removed main state usage for Sparky Main State Depreciation Jan '24
     * Updated to use alternative versions provided by Sparky team
     */

    // Get CDL tenant ID
    const currentSupportAccountId = authInfo?.instances?.get("prisma_access")?.support_account_id;
    const accountId = parseInt(currentSupportAccountId || 0);
    const tenantExt = auth?.instances?.get('logging_service');
    let lsTenantId;
    if (tenantExt?.info) {
        const tenantInfo = JSON.parse(tenantExt.info);
        if (tenantInfo) {
            lsTenantId = tenantInfo.tenant_id;
        }
    }

    // appType
    // "onprem" for “panorama”, "cloud" for cloud managed (adelphi)
    const appType = authInfo?.instances?.get('prisma_access')?.extra?.platform === "onprem" ? "prisma_access_panorama" : "prisma_access";

    // token manager
    const tenantId = authInfo?.instances?.get('prisma_access')?.tenant_id.replace('_onprem', '');

    // accountInfo
    const accountInfo = {
        accountId,
        accountName: auth?.accountInfo?.accountName,
        tenant: tenantId,
        lsTenantId,
    };
    const authDataComposed = {
        appType,
        accountInfo
    };
    return authDataComposed;
};

export const buildProps = (props) => {
    /** AIOPS-8509: Removed main state usage for Sparky Main State Depreciation Jan '24
     * Updated to use alternative versions provided by Sparky team
     */
    const { auth } = props;
    const authInfo = getAuthState();
    const loggedInUser = authInfo?.user;
    const { private_site_origin } = getFrameworkVars();

    // API is different per region in QA/Prod/ GCS app or local override will use the first one
    // const paiVars = Object.keys(getMicroAppVars("pai_oneapp") ?? {}).length && getMicroAppVars("pai_oneapp") || { apiServer: getFrameworkServiceUrl("cosmos") };
    const paiVars = getMicroAppVars("pai") ?? {}

    if (!paiVars.apiServer) {
        paiVars.apiServer = getFrameworkServiceUrl("cosmos")
    }
    const demVars = getMicroAppVars("dem_oneapp");
    const demServerFqdns = demVars?.api_server_url;
    const renderRoutes = Boolean(authInfo && authInfo?.instances);
    const authDataComposed = renderRoutes? 
        extractAuthDataComposed({ auth, authInfo }):
        { serverFqdns: paiVars, demServerFqdns };

    const { appType, accountInfo } = authDataComposed;
    const appPortalUrl = getFrameworkServiceUrl("app_portal");

    const providerProps = {
        initializePendo: false,
        authData: auth,
        initialState: {
            appType,
            accountInfo,
            appPortalUrl,
            serverFqdns: paiVars,
            demServerFqdns,
            locale: getUserLanguage(),
            /** AIOPS-8509: Changed to getState() as per Sparky Main State Depreciation Jan '24
             * This updates all references of getMainState() to fetch from top level state instead
             */
            getMainState: () => getState()
        },
    };

    const tenantFetcherProps = {};

    const featureFlags = getMicroAppVars('pai')?.featureFlags;
    const gcsTsgEnabled = !isNil(featureFlags) ? JSON.parse(featureFlags).gcs_tsg_enabled : false;

    const tenantId =
        hasTsgSupport && hasTsgSupport()
            ? (gcsTsgEnabled ? getCurrentTsgId() :
                (private_site_origin ? undefined : getCurrentTsgId())
            )
            : accountInfo?.lsTenantId;
    if (hasTsgSupport()) {
        providerProps.initialState.hasTsgSupport = hasTsgSupport;
        providerProps.initialState.tsgId = tenantId;
    }
    providerProps.initialState.getToken = () => {
        if (hasTsgSupport() || private_site_origin) {
            return (getAuthState() ?? auth)?.access_token;
        }
    };

    if (private_site_origin) {
        tenantFetcherProps.shouldLoadTenants = true;
        providerProps.standalone = true;
        providerProps.withAuth = true;
        providerProps.initialState.serverFqdns = paiVars;
        providerProps.initialState.isPrivateSite = true;
    } else {
        tenantFetcherProps.tenantId = tenantId;
    }

    return {
        providerProps,
        tenantFetcherProps,
        tenantId,
        loggedInUser,
    };
};
