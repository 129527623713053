import { useCallback, useEffect, useRef } from "react";
type Callback = () => boolean;
export default function useMountedComponent(): Callback {
    const ref = useRef(false);
    const callback = useCallback(() => ref.current, []);

    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        }
    }, [])
    return callback;
}
