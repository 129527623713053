
import { APIManagerInstance } from "../api";

const PRECACHE_HEARTBEAT_INTERVAL =  300000; //300000; // 5 mins

let precacheInterval: ReturnType<typeof setInterval> | null = null;

export const initializePrecache = (tenantId: string, subTenant: string) => {
    try {
        if(APIManagerInstance.isPrivateApp()) {
            APIManagerInstance.initPreCache(tenantId, subTenant).then(() =>{
                console.log('Initializing Precache executed')
            });
            
            // if interval is set, clear existing interval first before restarting interval
            if (precacheInterval) {
                clearInterval(precacheInterval);
                precacheInterval = null;
            }

            // communicate to server session is alive and caching updates continue
            precacheInterval =  setInterval(() => {
                APIManagerInstance.precacheHeartbeat(tenantId, subTenant)
            }, PRECACHE_HEARTBEAT_INTERVAL);
        }
    } catch (error) {
        console.debug('initialize precache error', error);
    }
}
