import React from 'react';
import { importExposed } from '@sparky/framework';
import { useLoadedComponent } from '../../utils/useLoadedComponent';

const LastUpdatedLoader = () => importExposed('pai', 'lastUpdatedWrapper');

export const LastUpdated = (props: any) => {
    const LastUpdatedComponent = useLoadedComponent(LastUpdatedLoader) as any;

    return LastUpdatedComponent == null ? <></> : <LastUpdatedComponent {...props} />;
};
