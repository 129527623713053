import { ReactNode, useState } from "react";
import { debounce } from "../../utils/debounce";
import { optionType } from "@panwds/react-ui";
import { APIManagerInstance } from "../../api/ApiManager";
import { history as sparkyHistory } from '@sparky/framework';

type useSearchProps = {
    tenantId: string,
    subtenant: string,
    categoryMap: any   
}

export const useSearch = ({
    tenantId,
    subtenant,
    categoryMap
}: useSearchProps) => {
    const [options, setOptions] = useState<optionType[]>([]);
    const [loading, setLoading] = useState(false);

    const emptyOption = [{
        key: "",
        group: "",
        options:  [{label: "", id: ""}]
    }]

    const transformSearchResults = (data: Record<any, any>[]) => {
        const results: optionType[] = [];

        Object.keys(data).forEach((dataKey: string) => {
            let record = [];
            if(dataKey === "pa_locations") {
                record = data[dataKey].map((option: Record<any, any>) => ({ "label": option.pa_location_name, "category": dataKey }));
            }  else if(dataKey == "users") {
                record = data[dataKey].map((option: Record<any, any>) => ({ "label": option.user_name, "alternate_label": option.adem_username ?? option.user_name, "category": dataKey, ...option }));
            }
            else if(dataKey === "applications") {
                record = data[dataKey].map((option: Record<any, any>) => ({ "label": option.application_name, "category": dataKey }));
            }
            else if(dataKey === "sites") {
                record = data[dataKey].map((option: Record<any, any>) => ({ "label": option.site_name, "category": dataKey, ...option }));
            }
            if(dataKey !== "search_result") {
                results.push({ key: dataKey, group: categoryMap[dataKey].label, options: record || [] });
            }
        });

        return results;
    }


    const navigateToDetailsPage = (selectedOption: Record<string, string>) => {
        if(!selectedOption) return;

        const { label, category, site_type, connection_method, alternate_label } = selectedOption;

        const routePath = categoryMap[category]["route"];

        if(category === "sites") {
            sparkyHistory.push(`${routePath[site_type]}?siteName=${label}`);
        } else if(category === "applications") {
            sparkyHistory.push(`${routePath}?appName=${label}`);
        } else if(category === "users") {
            sparkyHistory.push(`${routePath}?userName=${label}&ademUserName=${alternate_label}&connectedMethod=${connection_method}`);
        } else if(category === "pa_locations") {
            sparkyHistory.push(`${routePath}?prismaAccessLocation=${label}`);
        }
    };

    const handleInputChange = async (searchInput: string) => {
        setLoading(true);
        if(searchInput) {
            setOptions(emptyOption)
            
            const result = await APIManagerInstance.performSearch(searchInput, tenantId, subtenant);

            setOptions(transformSearchResults(result?.data?.[0]));

        }
        setLoading(false);
    };

    const debouncedHandleInputChange = debounce(handleInputChange);
    
    return {
        loading,
        options,
        debouncedHandleInputChange,
        navigateToDetailsPage
    }
}