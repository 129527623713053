import useTenantRequestHandler, { TenantFetcherInterface } from "../api/useTenantRequestHandler";
import React, { useEffect } from "react";
import { isNil } from "../utils/isNil";
import { LoadingIcon } from "@panwds/react-ui";
import { OneOf } from "../types/types";
import Logger from "../utils/Logger";

type TenantFetcherProps = OneOf<TenantFetcherInterface, "tenantId" | "shouldLoadTenants"> & {
    onTenantLoading?: (tenantId?: string) => void
}

export function TenantFetcher (props: TenantFetcherProps): JSX.Element {
    const { onTenantLoading, ...rest } = props;
    const { isLoading, hasError, tenant } = useTenantRequestHandler(rest);
    useEffect(() => {
        if (!isNil(tenant) && onTenantLoading) {
            onTenantLoading(rest.tenantId as unknown as string )
        }
    }, [props, rest.tenantId, tenant])

    useEffect(() => {
        if (isLoading && !hasError) {
            Logger.log("tenant data is loading");
        } else if(hasError) {
            Logger.log("Error occurred during tenant fetch");
        }
    }, [isLoading, hasError])

    return <></>;
}
