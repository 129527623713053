import React, { ReactNode, useState, useEffect } from 'react'
import { history as sparkyHistory } from '@sparky/framework'
import { PageLayout, Tab, TabList, TabPanel, EmptyStateLayout } from '@panwds/react-ui'
import HeaderContainer from '../HeaderContainer'
import { getSaseGlobalState, setSaseGlobalState } from '../../api/GlobalStore';
import { isNil } from '../../utils/isNil';

const Panel = ({ id, Component, tabs }: any) => {
  return (
    tabs.includes(id.toString()) ? isNil(Component) ? null : <Component /> : <EmptyStateLayout heading="This page intentionally left blank" />
  )
}

export const TabListContainer = (props: any) => {
  const { title = '', tabConfig, type } = props
  console.log("type", type)
  const { currentTabId } = getSaseGlobalState();
  const authorizedTabs = tabConfig;

  const selectedTabs = authorizedTabs.map((t: any) => t.id)
  const pageRoute = window.location.pathname;
  const pageQueryParams = window.location.search;

  const pathName = pageRoute.split('/');
  const userSelectedTab = pathName[pathName.length - 1];
  let currentTabRoute = "";
  let cachedValidTabRoute = "";

  // check if current route is a tab route and is one of the available tabs
  if (selectedTabs.includes(userSelectedTab)) {
    currentTabRoute = userSelectedTab;
  }

  const stitchedViewRoutes = ["data-centers", "branch-sites", "applications", "subscriptionUsage"];

  // check if cached route is a tab route and is one of the available tabs
  if (selectedTabs.includes(currentTabId)) {
    cachedValidTabRoute = currentTabId;
  }

  // sets the active tab from tab route, cache and first tab in the given order
  const [activeTab, setActiveTab] = useState<string>()

  useEffect(() => {
    if (selectedTabs && activeTab && selectedTabs.includes(activeTab)) {

      // strip away any unnneded paths, correct path should
      // be in following format: stitchedViewRoute -> tabRoute
      const stitchedRoute = stitchedViewRoutes.find(viewRoute => pageRoute.includes(viewRoute)) || "";
      const newRoute = `${pageRoute.split(stitchedRoute)[0]}${stitchedRoute}`;

      if (!currentTabRoute) {
        sparkyHistory.push(`${newRoute}/${activeTab}${pageQueryParams}`);
      }
      else {
        sparkyHistory.replace(`${newRoute}/${activeTab}${pageQueryParams}`);
      }

      setSaseGlobalState({ currentTabId: activeTab });
    }

  }, [activeTab, window.location.pathname]);

  useEffect(() => {
    setActiveTab(currentTabRoute || cachedValidTabRoute || selectedTabs?.[0])
  }, [currentTabRoute, cachedValidTabRoute]);

  /* The PageLayout component adds margin to its content by default, 
   * and the TabPanel component also adds margin. 
   * To avoid this double margin, we set noMargin={true} based on the condition of whether there are authorized tabs or not.
   */

  return (
    <PageLayout
      noMargin={authorizedTabs?.length > 0}
      documentTitle=""
      header={<HeaderContainer subtitle="&nbsp;" title={title} />}
      isLoading={false}
      tabList={authorizedTabs.length > 0 ?
        <TabList
          activeTabId={activeTab}
          onActiveChange={(nextTabId) => {
            setActiveTab(nextTabId);
            setSaseGlobalState({ currentTabId: nextTabId });
            sparkyHistory.push(`${pageRoute.split(`/${activeTab}`)[0]}/${nextTabId || activeTab}${pageQueryParams}`);
          }}
        >
          {authorizedTabs.map(({ id, label }: { id: string; label: string }) => (
            <Tab key={id} id={id} data-qa-id={`${id}-tab`}>
              {label}
            </Tab>
          ))}
        </TabList>
        : null}
    >

      {authorizedTabs.length > 0
        ? authorizedTabs.map(({ id, Component }: { id: string; Component: ReactNode }) => (
          <TabPanel key={id} activeTabId={activeTab} forTabId={id} data-qa-id={`${id}-tab-panel`} >
            <Panel key={id} id={id} Component={Component} tabs={selectedTabs} data-qa-id={`${id}-panel`} />
          </TabPanel>
        ))
        : authorizedTabs.length === 1 &&
        authorizedTabs.map(({ id, Component }: { id: string; Component: ReactNode }) => (
          <Panel key={id} id={id} Component={Component} tabs={selectedTabs} />
        ))}

    </PageLayout>
  )
}

