import { useState, useEffect, ReactNode } from 'react';
import { LoadingPage } from '@panwds/react-ui'

/**
 * Function that intakes an async loader function and returns an exported Component.
 * 
 * Prioritize passing in importExposed loader which bypasses errors associated with lazy import methods LazyExoticComponent<T>.
 * This avoids undesirable unmount side effects
 */
export const useLoadedComponent = (loader: any, loadingPage: ReactNode = LoadingPage) => {
    const [Component, setComponent] = useState(() => loadingPage);
    useEffect(() => {
       loader().then((module: any) => {
         setComponent(() => module?.default)
       })
    }, [])
    return Component;
 }