enum Level {
  FATAL,
  ERROR,
  WARN,
  INFO,
  DEBUG,
  TRACE,
}

export const LogLevel = Level

const dateFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

class Logger {
  private static instance: Logger
  private level: Level

  private constructor() {
    this.level = Level.ERROR
  }

  public static getInstance(): Logger {
    if (this.instance === null || this.instance === undefined) {
      Logger.instance = new this()
    }
    return this.instance
  }

  public setLevel(level: Level): void {
    this.level = level
  }

  public log(message: string, ...args: any): void {
    if (this.level >= 4) {
      Logger.logToConsole(message, Level.DEBUG, ...args)
    }
  }

  public fatal(message: string, ...args: any): void {
    Logger.logToConsole(message, Level.FATAL, ...args)
  }

  public error(message: string, ...args: any): void {
    Logger.logToConsole(message, Level.ERROR, ...args)
  }

  public warn(message: string, ...args: any): void {
    Logger.logToConsole(message, Level.WARN, ...args)
  }

  public info(message: string, ...args: any): void {
    Logger.logToConsole(message, Level.INFO, ...args)
  }

  public debug(message: string, ...args: any): void {
    Logger.logToConsole(message, Level.DEBUG, ...args)
  }

  public trace(message: string, ...args: any): void {
    Logger.logToConsole(message, Level.TRACE, ...args)
  }

  private static logToConsole(message: string, level: Level, ...args: any): void {
    if (typeof console !== 'undefined' && Logger.getInstance().shouldLog(level)) {
      const logLevelName = Level[level].toLowerCase()
      const formatedMessage = `${new Date().toLocaleString()} [${logLevelName}] ${message}`
      try {
        // @ts-expect-error: todo the type
        console[logLevelName](formatedMessage, ...args)
      } catch (e) {
        console.info(formatedMessage, ...args)
      }
    }
  }

  private shouldLog(level: Level): boolean {
    return level <= this.level
  }
}

export default Logger.getInstance()
