import { ErrorItf, IErrorCallback, ISuccessCallback } from '../types/types'
import { isNil } from '../utils/isNil'
import { AnalyticsType } from '../constants/uiConstants'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { isFn } from './isFn'

export type PreProcessorCallback = (
  response: Response | ApolloQueryResult<unknown>
) => Response | ApolloQueryResult<unknown>

class HTTPReqResponseInterceptor {
  private static instance: HTTPReqResponseInterceptor
  private readonly successCallbacks: Map<string, ISuccessCallback>
  private readonly errorCallbacks: Map<string, IErrorCallback>

  private constructor() {
    this.successCallbacks = new Map<string, ISuccessCallback>()
    this.errorCallbacks = new Map<string, IErrorCallback>()
  }

  public static getInstance(): HTTPReqResponseInterceptor {
    if (isNil(HTTPReqResponseInterceptor.instance)) {
      this.instance = new this()
    }
    return this.instance
  }

  public registerSuccessCallback(cb: ISuccessCallback): void {
    this.successCallbacks.set(cb.getId(), cb)
  }

  public unregisterSuccessCallback(cb: ISuccessCallback): void {
    this.successCallbacks.delete(cb.getId())
  }

  public registerErrorCallback(cb: IErrorCallback): void {
    this.errorCallbacks.set(cb.getId(), cb)
  }

  public unregisterErrorCallback(cb: IErrorCallback): void {
    this.errorCallbacks.delete(cb.getId())
  }

  public onSuccess(
    response: Response | ApolloQueryResult<unknown>,
    configType: AnalyticsType,
    requestId: string,
    preProcessor?: PreProcessorCallback
  ): void {
    for (const [, cb] of this.successCallbacks) {
      let processedResponse = response
      if (isFn(preProcessor)) {
        processedResponse = preProcessor!(response)
      }
      cb.onSuccess(processedResponse, configType, requestId)
    }
  }

  public onError(error: Error | ErrorItf, configType: AnalyticsType, requestId: string): void {
    for (const [, cb] of this.errorCallbacks) {
      let errorDetails: ErrorItf
      if (error instanceof Error) {
        errorDetails = {
          errorCode: '',
          message: error.message,
          details: error.stack,
        }
      } else {
        errorDetails = error as ErrorItf
      }

      cb.onError(errorDetails, configType, requestId)
    }
  }

  public onStartRequest(configType: AnalyticsType, requestId: string): void {
    for (const [, cb] of this.errorCallbacks) {
      cb.onStartRequest(configType, requestId)
    }
  }
}

export default HTTPReqResponseInterceptor.getInstance()
